module.exports = {
    NODE_ENV: process.env.NODE_ENV,
    API_URL: process.env.GATSBY_API_BASE_URL,
    GATSBY_BILLDESK_URL: process.env.GATSBY_BILLDESK_URL,
    BILLDESK_MERCHANT_ID : process.env.GATSBY_BILLDESK_MERCHANT_ID,
    BILLDESK_SECURITY_ID : process.env.GATSBY_BILLDESK_SECURITY_ID,
    // GATSBY_MAPS_KEY : process.env.GATSBY_MAPS_KEY,
    GATSBY_DRUPAL_USERNAME : process.env.GATSBY_DRUPAL_USERNAME,
    GATSBY_DRUPAL_PASSWORD : process.env.GATSBY_DRUPAL_PASSWORD,
    GATSBY_GOOGLE_RECAPTCHA_KEY : process.env.GATSBY_GOOGLE_RECAPTCHA_KEY,
    DOCTORS_FILTER: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
    DOCTORS: {
        DOCTORS: '/jsonapi/node/doctor',
        SPECIALITY: '/content-api/speciality',
        SPECIALITY_SEARCH: '/content-api/speciality/search',
        DOCTORS_DATA: '/content-api/doctors',
        BOOKING_SLOTS: '/api/getAllDoctorTimeSlots',
        GET_APPOINTMENT: '/api/getPatDetailsAptNo',
        BOOK_APPOINTMENT: '/api/patientBookAppointment',
        ALL_APPOINTMENT_BY_HH: '/api/getAllApointmentsForPatient',
        GET_SLOT_TYPES : '/api/getSlotType',
        GET_CHARGE_BY_SLOT_TYPES : '/api/getConsultationType',
        GET_ROOM_NO : '/api/getParticularDoctorRoomNo',
        GET_APPOINTMENT_CHARGES: '/api/getAppointmentCharges',
        BLOCK_TIME_SLOT: '/api/blocktimeslot',
        TELECONSULTATION: '/api/teleconsultation',
        TELECONSULTATION_AVAIL_DATE: '/api/telephoneconsultation'
    },
    EVENTS: {
        EVENTS: '/jsonapi/node/event',
        EVENTS_DATA: '/content-api/events',
        EVENTS_REGISTRATION: '/webform_rest/submit',
        EVENT_CHECKSUM: '/api/geteventChecksum',
        EVENTS_ARCHIVED_DATA: '/content-api/archived-events-data',
        EVENT_CALLBACK: process.env.GATSBY_API_BASE_URL + '/api/billdesk/eventscallBackUrl',
        VOUCHER_DETAILS: '/api/getEventVoucherdetails',
    },
    PRESS_RELEASE :  {
        MEDIA_CONTENT: '/content-api/news-media-contact',
        VIDEO: '/content-api/news-media-video',
        PRESS_RELEASE: '/content-api/press-releases',
        KEY_COVERAGE: '/content-api/key-coverarges',
        KEY_NEWS: '/content-api/key-news'
    },
    BLOGS: {
        BLOG_TYPES: '/jsonapi/taxonomy_term/tags',
        BLOGS_DATA: '/content-api/blogs',
        ARTICLES: '/content-api/queue',
        SUBMIT : '/webform_rest/submit',
        MOSTVIEWED: '/api/related-blog',
    },
    SITE_SEARCH: '/api/site-search',
    LOGIN: 'api/login',
    REGISTER: 'api/register',
    SEND_OTP: 'api/sendOTP',
    VERIFY_OTP: 'api/verifyOTP',
    SEND_EMAIL_OTP: 'api/sendOTP/email',
    VERIFY_EMAIL_OTP: '/api/verify_email_OTP',
    GET_PATIENT_FROM_HH : 'api/getPatientDetails',
    GET_PATIENT_FROM_MOBILE_NO: 'api/getPatientDetails',
    GET_RETRIVE_DATA_FROM_MOBILE_NO: 'api/getuserdata',
    VALIDATE_HH_NUMBER : 'api/validateHhNumber',
    VALIDATE_EX_NUMBER: 'api/validateExNumber',
    REGISTER_EVENT: '/api/events/register',
    REGISTERED_EVENTS: "/api/registered-events",
    CONTACTUS_ENQUIRY: "/webform_rest/submit",
    REFER_PATIENT: "/webform_rest/submit",
    SPECIALITIES: "/content-api/speciality",
    MONTHS: [
        {
            name: "January",
            value: 1
        },
        {
            name: "Feburary",
            value: 2
        },
        {
            name: "March",
            value: 3
        },
        {
            name: "April",
            value: 4
        },
        {
            name: "May",
            value: 5
        },
        {
            name: "June",
            value: 6
        },
        {
            name: "July",
            value: 7
        },
        {
            name: "August",
            value: 8
        },
        {
            name: "September",
            value: 9
        },
        {
            name: "October",
            value: 10
        },
        {
            name: "November",
            value: 11
        },
        {
            name: "December",
            value: 12
        },
    ],
    CAREER_LISTING: '/api/content?url=/career-listing',
    CAREER_FILE_UPLOAD: '/file/upload/media/document/field_media_document?_format=hal_json',
    IMAGE_UPLOAD : '/file/upload/media/image/field_media_image?_format=hal_json',
    REG_FILE_UPLOAD : '/file/upload/node/page/field_image?_format=hal_json',
    WEBFORM_SUBMIT: '/webform_rest/submit',
    GET_COMMON_SPECS:'/content-api/common/specialities',
    GET_POPULAR_SEARCH : '/content-api/popular/searches',
    GET_ALL_RELIGION: '/api/getReligion',
    GET_RELATION: '/api/getRelation',
    GET_MARITAL_STATUS: '/api/getMaritalStatus',
    GET_ALL_COUNTRY: '/api/getAllCountry',
    GET_STATES: '/api/getAllStateCountryWise',
    SEARCHES: {
        GET_POPULAR : '/api/popular-searchdata',
        POST_POPULAR : '/api/popular-search/data'
    },
    TESTIMONIAL_FORM: "/webform_rest/submit",
    PAYMENT: {
        CHECKSUM_API: '/api/getChecksum',
        CALLBACK: process.env.GATSBY_API_BASE_URL + '/api/billdesk/callBackUrl',
        CONVERT_JSON_DATA: '/api/storeVoucherData',
        GET_PAYMENT_DETAILS: '/api/getVoucherdetails',
    },
    GET_PATIENT_BY_ADMISSION_NO: '/api/getPatientAdmissionNoDetails',
    GET_PATIENT_BY_RESERVATION_NO: '/api/getPatientReservationNoDetails',
    GET_FAMILY_MEMBERS: '/api/getFamilyMembers',
    ADD_FAMILY_MEMBER: '/api/saveFamilyMembers',
    UPDATE_PATIENT : '/api/updatePatientDetails',
    EMAIL_INTERIM_BILL : '/api/emailInterimBill',
    GET_INTERIM_BILL : '/api/getInterimBill',
    GET_ALL_MEDICAL_REPORTS: '/api/getAllOrders',
    GET_MEDICAL_REPORT_DETAILS: '/api/getAllOrdersList',
    GET_PATIENT_DETAILS_FROM_ORDER_NO: '/api/getPatientDetailsFromOrderNo',
    PROFILE: {
        GET_FAMILY_HISTORY: '/api/getFamilyHistoryQuestionDetails',
        POST_FAMILY_HISTORY: '/api/postFamilyHistoryQuestionDetails',
        GET_PAST_HISTORY: '/api/getPastHistoryQuestionDetails',
        POST_PAST_HISTORY: '/api/postPastHistoryQuestionDetails',
        GET_LIFESTYLE_HISTORY: '/api/getLifeStyleHistoryQuestionDetails',
        POST_LIFESTYLE_HISTORY: '/api/postLifeStyleQuestionDetails'
    },
    GET_PRINT_VOUCHER: '/api/print-voucher',
    GET_SITEMAP_MENU: '/api/menu_items/sitemap-menu',
}